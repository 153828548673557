var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add_coupon_dialog" },
    [
      _c("h2", { staticStyle: { textAlign: "center" } }, [
        _vm._v(" 创建" + _vm._s(_vm.msgs[_vm.couponForm.couponSubType]) + " "),
      ]),
      _c(
        "el-form",
        {
          ref: "couponForm",
          attrs: { rules: _vm.rules, model: _vm.couponForm, size: "mini" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "优惠券类型:", prop: "couponSubType" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: "",
                  value: _vm.msgs[_vm.couponForm.couponSubType],
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "优惠券名称:", prop: "couponName" } },
            [
              _c("el-input", {
                attrs: { "show-word-limit": "", maxlength: "10" },
                model: {
                  value: _vm.couponForm.couponName,
                  callback: function ($$v) {
                    _vm.$set(_vm.couponForm, "couponName", $$v)
                  },
                  expression: "couponForm.couponName",
                },
              }),
            ],
            1
          ),
          _vm.couponForm.couponSubType != 204
            ? _c(
                "el-form-item",
                { attrs: { label: "面额:", prop: "discountsValue" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      precision: 0,
                      max: 10000,
                      min: 1,
                      placeholder: "1-10000",
                    },
                    model: {
                      value: _vm.couponForm.discountsValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.couponForm, "discountsValue", $$v)
                      },
                      expression: "couponForm.discountsValue",
                    },
                  }),
                  _vm._v(" 元 "),
                ],
                1
              )
            : _vm._e(),
          _vm.couponForm.couponSubType != 204
            ? _c(
                "el-form-item",
                { attrs: { label: "使用条件:", prop: "discountsCondition" } },
                [
                  _vm._v(" 满 "),
                  _c("el-input-number", {
                    attrs: {
                      precision: 0,
                      max: 10000,
                      min: 1,
                      placeholder: "1-10000",
                    },
                    model: {
                      value: _vm.couponForm.discountsCondition,
                      callback: function ($$v) {
                        _vm.$set(_vm.couponForm, "discountsCondition", $$v)
                      },
                      expression: "couponForm.discountsCondition",
                    },
                  }),
                  _vm._v(" 元可用 "),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "发放总量:", prop: "couponCount" } },
            [
              _c("el-input-number", {
                attrs: {
                  precision: 0,
                  min: 1,
                  max: 100000,
                  placeholder: "1-100000",
                  disabled: _vm.couponForm.couponSubType === 204,
                },
                model: {
                  value: _vm.couponForm.couponCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.couponForm, "couponCount", $$v)
                  },
                  expression: "couponForm.couponCount",
                },
              }),
              _vm._v(" 张 "),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "有效时间:", prop: "valid_time" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "range-separator": "-",
                  "value-format": "timestamp",
                  "default-time": ["00:00:00", "23:59:59"],
                },
                model: {
                  value: _vm.couponForm.valid_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.couponForm, "valid_time", $$v)
                  },
                  expression: "couponForm.valid_time",
                },
              }),
            ],
            1
          ),
          _vm.couponForm.couponSubType == 201 ||
          _vm.couponForm.couponSubType == 204
            ? _c(
                "el-form-item",
                { attrs: { required: "", label: "活动商品:" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addCoupon },
                    },
                    [_vm._v(" 添加优惠券商品 ")]
                  ),
                  +_vm.couponForm.couponSubType === 204
                    ? _c("span", [
                        _vm._v(
                          " （注意：1张券仅可兑换1件商品。请将优惠券商品的起购量设置为1，否则将无法兑换。） "
                        ),
                      ])
                    : _vm._e(),
                  _vm.outerTableData.length > 0
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            width: "600px",
                            "max-height": "800px",
                            overflow: "auto",
                          },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { "min-width": "1000px" },
                              attrs: { data: _vm.outerTableData },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "index", width: "50" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "商品信息",
                                  "min-width": "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "goods_message" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "left" },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: scope.row.imageUrls,
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "right" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "title" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row.name
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1249970118
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "id",
                                  label: "商品ID",
                                  width: "80",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "stockQuantity",
                                  label: "库存",
                                  width: "80",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "id",
                                  label: "原价(元)",
                                  width: "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.minSellPrice) +
                                              " - " +
                                              _vm._s(scope.row.maxSellPrice) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  780853859
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "操作", "min-width": "60" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toDelete(
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 删除 ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  630375991
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.couponForm.couponSubType == 203
            ? _c(
                "el-form-item",
                { attrs: { required: "", label: "直播活动:" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.addLive } },
                    [_vm._v(" 添加直播活动 ")]
                  ),
                  _c("p", { staticClass: "intro" }, [
                    _vm._v(" 说明：最多可添加1个直播活动 "),
                  ]),
                  _vm.outerLiveTableData.length >= 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "77%" },
                              attrs: { data: _vm.outerLiveTableData },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "title",
                                  label: "直播名称",
                                  "min-width": "110",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "id",
                                  label: "直播ID",
                                  "min-width": "110",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "actorName",
                                  label: "主播",
                                  "min-width": "110",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { label: "操作", "min-width": "80" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toDeleteLive(
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 删除 ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1664596353
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "每人限领:", prop: "personGetCount" } },
            [
              _c("el-input-number", {
                attrs: {
                  precision: 0,
                  max: 9999,
                  min: 1,
                  placeholder: "1-9999",
                },
                model: {
                  value: _vm.couponForm.personGetCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.couponForm, "personGetCount", $$v)
                  },
                  expression: "couponForm.personGetCount",
                },
              }),
              _vm._v(" 张 "),
            ],
            1
          ),
          _vm.couponForm.couponSubType != 204
            ? _c(
                "el-form-item",
                { attrs: { label: "设置为公开券:" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.couponForm.isShow,
                        callback: function ($$v) {
                          _vm.$set(_vm.couponForm, "isShow", $$v)
                        },
                        expression: "couponForm.isShow",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { margin: "6px 0 10px" } },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(" 是  用户可在商家店铺、领券中心领取 "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v(
                              " 否  不支持用户在店铺中领取，仅支持商家主动发放 "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { staticStyle: { textAlign: "center" }, attrs: { label: " " } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.cancel()
                    },
                  },
                },
                [_vm._v(" 取 消 ")]
              ),
              _vm.operateType !== "show"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("couponForm", _vm.couponForm)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.operateType == "add" ? "创 建" : "编 辑") +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.innerVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                width: "80%",
                title: "选择优惠券商品",
                visible: _vm.innerVisible,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.innerVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.innerTableData,
                        border: "",
                        "row-key": _vm.getRowKey,
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "55",
                          align: "center",
                          "reserve-selection": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "商品信息", "min-width": "160" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "goods_message" }, [
                                    _c("div", { staticClass: "left" }, [
                                      _c("img", {
                                        attrs: { src: scope.row.imageUrls },
                                      }),
                                    ]),
                                    _c("div", { staticClass: "right" }, [
                                      _c("div", { staticClass: "title" }, [
                                        _vm._v(
                                          " " + _vm._s(scope.row.name) + " "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1249970118
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "id", label: "商品ID", width: "210" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "stockQuantity",
                          label: "库存",
                          width: "210",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "id", label: "原价(元)", width: "210" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.minSellPrice) +
                                      " - " +
                                      _vm._s(scope.row.maxSellPrice) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          780853859
                        ),
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    attrs: {
                      small: "",
                      "current-page": _vm.innerPagination.page,
                      "page-sizes": [10, 20, 30, 50],
                      "page-size": _vm.innerPagination.page_count,
                      layout: "prev, pager, next",
                      total: _vm.innerPagination.item_total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSure } },
                    [_vm._v(" 确认添加 ")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.innerLiveVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                width: "80%",
                title: "选择直播活动",
                visible: _vm.innerLiveVisible,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.innerLiveVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.innerLiveTableData,
                        border: "",
                        "row-key": _vm.getRowKey,
                      },
                      on: { "selection-change": _vm.handleLiveSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "55",
                          align: "center",
                          "reserve-selection": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "title",
                          label: "直播名称",
                          "min-width": "110",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "id", label: "直播ID", width: "210" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "actorName",
                          label: "主播",
                          width: "210",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.onLiveSure },
                    },
                    [_vm._v(" 确认添加 ")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }