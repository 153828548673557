<template>
  <div class="add_coupon_dialog">
    <h2 style="textAlign: center">
      创建{{ msgs[couponForm.couponSubType] }}
    </h2>
    <el-form
      ref="couponForm"
      :rules="rules"
      :model="couponForm"
      size="mini"
    >
      <el-form-item
        label="优惠券类型:"
        prop="couponSubType"
      >
        <el-input
          disabled
          :value="msgs[couponForm.couponSubType]"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="优惠券名称:"
        prop="couponName"
      >
        <el-input
          v-model="couponForm.couponName"
          show-word-limit
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="couponForm.couponSubType != 204"
        label="面额:"
        prop="discountsValue"
      >
        <el-input-number
          v-model="couponForm.discountsValue"
          :precision="0"
          :max="10000"
          :min="1"
          placeholder="1-10000"
        /> 元
      </el-form-item>
      <el-form-item
        v-if="couponForm.couponSubType != 204"
        label="使用条件:"
        prop="discountsCondition"
      >
        满 <el-input-number
          v-model="couponForm.discountsCondition"
          :precision="0"
          :max="10000"
          :min="1"
          placeholder="1-10000"
        /> 元可用
      </el-form-item>
      <el-form-item
        label="发放总量:"
        prop="couponCount"
      >
        <el-input-number
          v-model="couponForm.couponCount"
          :precision="0"
          :min="1"
          :max="100000"
          placeholder="1-100000"
          :disabled="couponForm.couponSubType === 204"
        /> 张
      </el-form-item>
      <el-form-item
        label="有效时间:"
        prop="valid_time"
      >
        <el-date-picker
          v-model="couponForm.valid_time"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          range-separator="-"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item
        v-if="couponForm.couponSubType == 201 || couponForm.couponSubType == 204"
        required
        label="活动商品:"
      >
        <el-button
          type="primary"
          @click="addCoupon"
        >
          添加优惠券商品
        </el-button>
        <span v-if="+couponForm.couponSubType === 204">
          （注意：1张券仅可兑换1件商品。请将优惠券商品的起购量设置为1，否则将无法兑换。）
        </span>
        <!-- <p class="intro">
          说明：最多可添加100个商品
        </p> -->
        <div
          v-if="outerTableData.length > 0"
          style="width: 600px; max-height: 800px; overflow: auto"
        >
          <el-table
            :data="outerTableData"
            style="min-width: 1000px"
          >
            <el-table-column
              type="index"
              width="50"
            >
            </el-table-column>
            <el-table-column
              label="商品信息"
              min-width="150"
            >
              <template slot-scope="scope">
                <div class="goods_message">
                  <div class="left">
                    <img :src="scope.row.imageUrls" />
                  </div>
                  <div class="right">
                    <div class="title">
                      {{ scope.row.name }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="id"
              label="商品ID"
              width="80"
            />
            <el-table-column
              prop="stockQuantity"
              label="库存"
              width="80"
            />
            <el-table-column
              prop="id"
              label="原价(元)"
              width="150"
            >
              <template slot-scope="scope">
                {{ scope.row.minSellPrice }} - {{ scope.row.maxSellPrice }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              min-width="60"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  @click="toDelete(scope.$index)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>
      <el-form-item
        v-if="couponForm.couponSubType == 203"
        required
        label="直播活动:"
      >
        <el-button
          type="primary"
          @click="addLive"
        >
          添加直播活动
        </el-button>
        <p class="intro">
          说明：最多可添加1个直播活动
        </p>
        <div v-if="outerLiveTableData.length >= 0">
          <el-table
            :data="outerLiveTableData"
            style="width: 77%"
          >
            <el-table-column
              prop="title"
              label="直播名称"
              min-width="110"
            />
            <el-table-column
              prop="id"
              label="直播ID"
              min-width="110"
            />
            <el-table-column
              prop="actorName"
              label="主播"
              min-width="110"
            />
            <el-table-column
              label="操作"
              min-width="80"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  @click="toDeleteLive(scope.$index)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>

      <el-form-item
        label="每人限领:"
        prop="personGetCount"
      >
        <el-input-number
          v-model="couponForm.personGetCount"
          :precision="0"
          :max="9999"
          :min="1"
          placeholder="1-9999"
        /> 张
        <!-- <el-select
          v-model="couponForm.personGetCount"
          placeholder="请选择1-10张"
        >
          <el-option
            v-for="item in personGetCountOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
      </el-form-item>

      <el-form-item
        v-if="couponForm.couponSubType != 204"
        label="设置为公开券:"
      >
        <el-radio-group v-model="couponForm.isShow">
          <div style="margin: 6px 0 10px">
            <el-radio :label="1">
              是&nbsp;&nbsp;用户可在商家店铺、领券中心领取
            </el-radio>
          </div>
          <div>
            <el-radio :label="0">
              否&nbsp;&nbsp;不支持用户在店铺中领取，仅支持商家主动发放
            </el-radio>
          </div>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        style="textAlign: center"
        label=" "
      >
        <el-button
          type="primary"
          @click="cancel()"
        >
          取 消
        </el-button>
        <el-button
          v-if="operateType !== 'show'"
          type="primary"
          @click="submitForm('couponForm', couponForm)"
        >
          {{ operateType == 'add' ? '创 建' : '编 辑' }}
        </el-button>
      </el-form-item>
    </el-form>

    <el-dialog
      v-if="innerVisible"
      width="80%"
      title="选择优惠券商品"
      :visible.sync="innerVisible"
      :close-on-click-modal="false"
    >
      <div>
        <el-table
          :data="innerTableData"
          border
          style="width: 100%"
          :row-key="getRowKey"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
            :reserve-selection="true"
          />
          <el-table-column
            label="商品信息"
            min-width="160"
          >
            <template slot-scope="scope">
              <div class="goods_message">
                <div class="left">
                  <img :src="scope.row.imageUrls" />
                </div>
                <div class="right">
                  <div class="title">
                    {{ scope.row.name }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="id"
            label="商品ID"
            width="210"
          />
          <el-table-column
            prop="stockQuantity"
            label="库存"
            width="210"
          />
          <el-table-column
            prop="id"
            label="原价(元)"
            width="210"
          >
            <template slot-scope="scope">
              {{ scope.row.minSellPrice }} - {{ scope.row.maxSellPrice }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          small
          :current-page="innerPagination.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="innerPagination.page_count"
          layout="prev, pager, next"
          :total="innerPagination.item_total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="onSure"
        >
          确认添加
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      v-if="innerLiveVisible"
      width="80%"
      title="选择直播活动"
      :visible.sync="innerLiveVisible"
      :close-on-click-modal="false"
    >
      <div>
        <el-table
          :data="innerLiveTableData"
          border
          style="width: 100%"
          :row-key="getRowKey"
          @selection-change="handleLiveSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
            :reserve-selection="true"
          />
          <el-table-column
            prop="title"
            label="直播名称"
            min-width="110"
          />
          <el-table-column
            prop="id"
            label="直播ID"
            width="210"
          />
          <el-table-column
            prop="actorName"
            label="主播"
            width="210"
          />
        </el-table>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="mini"
          @click="onLiveSure"
        >
          确认添加
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AddEditCoupon',
  data() {
    this.msgs = { 201: '商品立减券', 202: '店铺满减券', 203: '直播优惠券', 204: '兑换券'};

    return {
      operateType: this.$route.query.operateType,
      subCouponList: [],
      innerVisible: false,
      innerTableData: [],
      innerLoading: false,
      innerPagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10
      },
      outerTableData: [],
      outerLoading: false,
      multipleSelection: [], // 选中的

      innerLiveVisible: false,
      innerLiveTableData: [],
      innerLiveLoading: false,
      outerLiveTableData: [],
      outerLiveLoading: false,
      multipleLiveSelection: [], // 选中的
      couponForm: {
        couponType: 2, // 商家后台
        couponSubType: this.$route.query.couponSubType,
        couponName: '',
        valid_time: [],
        get_time: [],
        couponCount: '',
        personGetCount: '',
        reationsId: [],
        validTimeType: 1,
        discountsConditiontype: 1,
        discountsType: 1,
        isShow: 1 // 是否公开券
      },
      rules: {

        couponName: [
          { required: true, message: '请输入优惠券名称', trigger: 'blur' }
        ],
        discountsCondition: [
          { required: true, message: '请输入使用条件', trigger: 'blur' }
        ],
        discountsValue: [
          { required: true, message: '请输入面额', trigger: 'blur' }
        ],
        valid_time: [
          { required: true, message: '请选择有效时间', trigger: 'change' }
        ],
        get_time: [
          { required: true, message: '请选择领取时间', trigger: 'change' }
        ],
        couponCount: [
          { required: true, message: '请输入发放总量', trigger: 'blur' }
        ],
        personGetCount: [
          { required: true, message: '请选择每人限领数量', trigger: 'change' }
        ]
      },
      personGetCountOptions: [
        {
          value: 1,
          label: '1'
        }, {
          value: 2,
          label: '2'
        }, {
          value: 3,
          label: '3'
        }, {
          value: 4,
          label: '4'
        }, {
          value: 5,
          label: '5'
        }, {
          value: 6,
          label: '6'
        }, {
          value: 7,
          label: '7'
        }, {
          value: 8,
          label: '8'
        }, {
          value: 9,
          label: '9'
        }, {
          value: 10,
          label: '10'
        }
      ]
    }
  },
  created() {
    const {couponSubType} = this.$route.query;
    if (this.operateType == 'edit' || this.operateType == 'show') {
      this.getDetail()
      if (couponSubType == 201 || couponSubType == 204) {
        this.getGoodsList()
      }
      if (couponSubType == 203) {
        this.getLiveList()
      }
    }
  },
  methods: {
    comType(val) {
      return val == 201 ? '商品立减券' : val == 202 ? '店铺满减券' : '直播商品券'
    },
    // 分页控制
    handleSizeChange(val) {
      this.innerPagination.page_count = val
      this.innerPagination.page = 1
      this.getInnerTable()
    },
    handleCurrentChange(val) {
      this.innerPagination.page = val
      this.getInnerTable()
    },
    getDetail() {
      this.$axios(this.$api.coupon.template_detail, {
        params: {
          id: this.$route.query.id
        }
      }).then(res => {
        if (res.code === 0) {
          this.couponForm = {
            ...res.data,
            valid_time: [res.data.validStartTime, res.data.validEndTime],
            couponCount: res.data.couponTotalCount
          }
        }
      })
    },

    getGoodsList() {
      this.$axios(this.$api.coupon.template_goods_list, {
        params: {
          couponid: this.$route.query.id,
          currentpage: 1,
          pagesize: 100
        }
      }).then(res => {
        if (res.code === 0) {
          this.outerTableData = res.data.records
        }
      })
    },
    getLiveList() {
      this.$axios(this.$api.coupon.template_live_list, {
        params: {
          couponid: this.$route.query.id,
          currentpage: 1,
          pagesize: 11
        }
      }).then(res => {
        if (res.code === 0) {
          this.outerLiveTableData = res.data.records
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.couponForm, 'couponForm')
          let url = this.operateType == 'add' ? this.$api.coupon.template_add : this.$api.coupon.template_edit

          this.couponForm.reationsId = []
          if (this.couponForm.couponSubType == 201 || this.couponForm.couponSubType == 204) { // 商品、兑换券
            this.couponForm.itemType = 2
            this.outerTableData.map(item => {
              this.couponForm.reationsId.push(item.id)
            })
          } else if (this.couponForm.couponSubType == 202) { // 店铺券
            this.couponForm.itemType = 1
            this.couponForm.reationsId.push(this.$route.query.storeId)
          } else if (this.couponForm.couponSubType == 203) { // 直播券
            this.couponForm.itemType = 5
            this.outerLiveTableData.map(item => {
              this.couponForm.reationsId.push(item.id)
            })
          }

          if (this.couponForm.reationsId.length <= 0) {
            return this.$message.warning('请添加必填项')
          } else {
            this.$axios.post(
              url,
              {
                ...this.couponForm,
                validStartTime: this.couponForm.valid_time ? this.couponForm.valid_time[0] : '',
                validEndTime: this.couponForm.valid_time ? this.couponForm.valid_time[1] : '',
                storeId: this.$route.query.storeId
              }
            ).then((res) => {
              if (res.code === 0) {
                this.$message.success(`${this.operateType == 'add' ? '创建成功' : '编辑成功'}`)
                this.$router.go(-1)
              }
            }).catch((error) => {
              console.log(error)
            })
          }
        } else {
          // 验证失败处理
          return false
        }
      })
    },
    cancel() {
      this.$router.go(-1)
    },
    getInnerTable() {
      let _this = this
      _this.innerLoading = true

      const params = {
        currentPage: _this.innerPagination.page,
        pageSize: _this.innerPagination.page_count,
        storeId: this.$route.query.storeId
      };
      if (this.couponForm.couponSubType !== '204') params.goodsTypes = [0, 1];
      _this.$axios.post(
        _this.$api.coupon.goods_add_list,
        params
      ).then(function(res) {
        if (res.code === 0) {
          const { size, total, current, pages } = res.data
          _this.innerPagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total)
          }
          _this.innerTableData = res.data.records
        } else {
          _this.innerTableData = []
          _this.innerPagination.item_total = 0
        }
        _this.innerLoading = false
      }).catch(function(error) {
        console.log(error)
      })
    },
    getInnerLiveTable() {
      let _this = this
      _this.innerLiveLoading = true
      _this.$axios(
        _this.$api.coupon.live_add_list,
        {
          params: {
            storeId: this.$route.query.storeId
          }
        }
      ).then(function(res) {
        if (res.code === 0) {
          _this.innerLiveTableData = res.data
        } else {
          _this.innerLiveTableData = []
        }
        _this.innerLiveLoading = false
      }).catch(function(error) {
        console.log(error)
      })
    },
    addLive() {
      this.innerLiveVisible = true
      this.getInnerLiveTable()
    },
    onLiveSure() {
      this.innerLiveVisible = false
      if (this.multipleLiveSelection.length > 1) {
        return this.$message.warning('最多可添加1个直播活动')
      } else {
        this.outerLiveTableData = this.multipleLiveSelection
      }
      let obj = {}
      this.outerLiveTableData = this.outerLiveTableData.reduce((item, next) => { // 去重
        obj[next.id] ? '' : obj[next.id] = true && item.push(next)
        return item
      }, [])
      console.log('outerLiveTableData:', this.outerLiveTableData)
    },
    handleLiveSelectionChange(val) {
      this.multipleLiveSelection = val
    },
    addCoupon() {
      this.innerVisible = true
      this.getInnerTable()
    },
    onSure() {
      this.innerVisible = false
      // 2023-1-16 陶园-运营需要添加大于10个商品
      // if (this.multipleSelection.length > 10) {
      //   return this.$message.warning('最多可添加10个商品')
      // } else {
      this.outerTableData = this.multipleSelection
      // }
      let obj = {}
      this.outerTableData = this.outerTableData.reduce((item, next) => { // 去重
        obj[next.id] ? '' : obj[next.id] = true && item.push(next)
        return item
      }, [])
      console.log('outerTableData:', this.outerTableData)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    getRowKey(row) {
      return row.id
    },
    toDelete(index) {
      this.outerTableData.splice(index, 1)
    },

    toDeleteLive(index) {
      this.outerLiveTableData.splice(index, 1)
    }

  }

}
</script>

<style lang="scss" scoped>
.add_coupon_dialog {
    background: #fff;
    padding: 10px;
    h2 {
        margin-bottom: 20px;
        font-weight: 700;
        text-align: center;
    }

    .intro {
        margin: 10px 0;
    }
}
</style>

<style lang="scss">
.el-form-item {
    .el-form-item__label {
        width: 30%;
    }
    .el-form-item__content {
        display: inline-block;
        width: 40%;
    }
    .el-form-item__content .el-input-group{
        vertical-align: middle;
    }

    @media screen and (max-width: 500px){
        .el-form-item__label {
            width: 32%;
        }
        .el-form-item__content {
            width: 60%;
        }
        .el-date-editor--datetimerange.el-input__inner {
            width: 200px;
        }
    }

}

</style>

<style lang="scss">
 .goods_message {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    .left {
      margin: 10px 15px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .right {
      .title {
        font-weight: 700;
        margin: 10px 0;
      }
      .id {
        color: #999;
      }
    }
  }
</style>
