// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.el-form-item .el-form-item__label {\n  width: 30%;\n}\n.el-form-item .el-form-item__content {\n  display: inline-block;\n  width: 40%;\n}\n.el-form-item .el-form-item__content .el-input-group {\n  vertical-align: middle;\n}\n@media screen and (max-width: 500px) {\n.el-form-item .el-form-item__label {\n    width: 32%;\n}\n.el-form-item .el-form-item__content {\n    width: 60%;\n}\n.el-form-item .el-date-editor--datetimerange.el-input__inner {\n    width: 200px;\n}\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
